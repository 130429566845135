<template>
  <div class="forgotBackground">
    <div class="forgotLogo">
      <img src="../assets/uclogo_white.png" alt="" />
    </div>
    <!-- START FORGOT PASSWORD CARD  -->
    <div class="forgot-password-card">
      <div class="login-form">
        <div class="form-group">
          <div class="input-lbl">
            <label for="user_login">Username or Email Address</label>
          </div>
          <b-form-input
            type="text"
            class="form-control"
            id="user_login"
            v-model="user_login"
            size="sm"
          />
        </div>
        <div class="card-actions pt-6">
          <b-button
            size="sm"
            type="submit"
            class="btn btn-primary pt-6"
            @click="forgotPassword"
          >
            Request Reset Link
          </b-button>
        </div>
      </div>
    </div>
    <!-- END FORGOT PASSWORD CARD  -->
  </div>
</template>
<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      user_login: "",
    };
  },
  methods: {
    forgotPassword() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_login: this.user_login }),
      };
      fetch(
        `${process.env.VUE_APP_API_URL}/users/forgot_pass`,
        requestOptions
      )
        .then((res) => {
          res.json().then((json) => {
            this.makeToast({
              message: json.message,
              variant: json.status == "success" ? "success" : "danger",
            });
            if (json.status == "success") {
              setTimeout(() => {
                this.$router.push("/");
              }, 2000);
            }
          });
        })
        .catch(() => {
          // console.log("err", err);
          this.makeToast({
            message:
              "Error: It seems we may being having a technical difficulties. Please try again later.",
            variant: "danger",
          });
        });
    },
  },
};
</script>

