<template>
  <b-modal
    id="cc-form-modal"
    centered
    :title="this.modalTitle"
    cancel-disabled
    ok-disabled
    @shown="shown"
  >
    <template>
      <label for="ccNumber">Card Number</label>
      <span id="ccNumber" class="d-block form-field">
        <!--VGS Collect iframe for expiration date field will be here!-->
      </span>
      <label class="mt-2" for="ccExpDate">Card Expiration Date</label>
      <span id="ccExpDate" class="d-block form-field">
        <!--VGS Collect iframe for expiration date field will be here!-->
      </span>
    </template>
    <template #modal-footer="{  /*hide, ok */ }">
      <b-button
        variant="success"
        :disabled="!vgsFormValidated || stage === 'processing'"
        @click="submitNewCard()"
      >
        Submit
        <b-spinner
          style="width: 20px; height: 20px"
          v-if="stage === 'processing'"
          label="Loading..."
        ></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { loadVGSCollect } from '@vgs/collect-js';
export default {
  data() {
    return {
      stage: '',
      vgsForm: null,
      vgsFormValidated: false,
      cardType: "",
      modalTitle: "Add a new card",
    };
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    selectedPartner: {
      type: Object,
      default: () => ({}),
    },
    modalMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
  },
  methods: {
    submitNewCard() {
      this.stage = 'processing'
      const cardType = this.cardType
      this.vgsForm.submit("/post", {}, (status, data) => {
        if(status == 200) {
          this.selectedPartner.vgs_card_type = cardType
          this.selectedPartner.vgs_card_number_alias = data.card_number
          this.selectedPartner.vgs_card_expirationDate_alias = data.card_expirationDate

          this.$emit("updatePartner");
        } else {
          this.makeToast({ 
            message: 'Error: Failed to alias CC info. Please contact an admin to resolve this issue.', 
            variant: "danger" 
          });
        }
        this.stage = ''
      })
    },
    async shown() {
      this.modalTitle = 'Add a new card for '+(this.selectedPartner ? this.selectedPartner.partner_name : '');
      //console.log("mounted", this.selectedPartner)
      // load script
      const collect = await loadVGSCollect({
        vaultId: process.env.VUE_APP_VGS_VAULT_ID,
        environment: process.env.VUE_APP_VGS_VAULT_ENV,
        version: '2.12.0'
      }).catch((e) => {
        this.$bvModal.hide('cc-form-modal')
        console.log(e)
        // script was not loaded
      });

    
      this.vgsForm = collect.init(state => {
        this.cardType = state.card_number ? state.card_number.cardType : '';
        var valid = true
        if(!state || !state.length > 1) { 
          valid = false; 
        } else {
          //console.log(typeof state)
          for (const [key] of Object.entries(state)) {
            //console.log(state[key])
            if(!state[key].isValid) valid = false;
          }
        }

        this.vgsFormValidated = valid
      });

      this.vgsForm.useCname('live.vault.universitycredit.com');

      var css_settings = {
        'display': 'block',
        'color': '#1b1d1f',
        'height': '50px',
        'border': 'solid 1px #ddd',
        'transition': 'border-color .15s ease-in-out',
        'border-radius': '3px',
        'margin': '10px 0',
        'padding': '0 10px',
        'box-sizing': 'border-box',
        '&:focus': {
          'border-color': '#11bef5',
        },
        '&.invalid.touched': {
          'color': 'red',
        },
        '&.valid': {
          'color': 'green',
        },
        'font-family': '"PT Mono", monospace',
    };
      // Create VGS Collect field for credit card number
      this.vgsForm.field("#ccNumber", {
        type: "card-number",
        name: "card_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "",
        validations: ["required", "validCardNumber"],
        css: css_settings,
        showCardIcon: true,
      });

      // Create VGS Collect field for credit card expiration date
      this.vgsForm.field("#ccExpDate", {
        type: "card-expiration-date",
        serializers: [{ name: 'replace', options: { old:' / ', new:'' }}],
        yearLength: '2',
        name: "card_expirationDate",
        placeholder: "MM / YY",
        validations: ["required", "validCardExpirationDate"],
        css: css_settings,
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        timeZone: "America/Los_Angeles",
      });
    },
  },
};
</script>


<style scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
span {
  max-height: 5rem;
}
</style>