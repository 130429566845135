import { render, staticRenderFns } from "./PartnerProfile.vue?vue&type=template&id=436ef206&scoped=true"
import script from "./PartnerProfile.vue?vue&type=script&lang=js"
export * from "./PartnerProfile.vue?vue&type=script&lang=js"
import style0 from "./PartnerProfile.vue?vue&type=style&index=0&id=436ef206&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436ef206",
  null
  
)

export default component.exports