<template>
  <div class="PartnerProfile">
    <ModalCcForm
      @updatePartner="updatePartner"
      :selectedPartner="selectedPartner"
      stage=""

    />
    <Header ref="Header" parent="partnerProfile" />
    <div class="partnerProfile">
        <div class="main row">
          <div class="col-6 col-md-6">
            <h3 class="mb-5">Partner Profiles</h3>
          </div>
          <div class="col-6 col-md-6 text-right">
            <b-button
              variant="secondary"
              class="btn ml-2"
              @click="$router.push('dashboard')"
              >Back to Dashboard</b-button
            >
          </div>
          <div
            v-for="(partner, p) in $store.state.user.adminPartners"
            :key="p"
            class="col-6 col-md-6"
          >
            <label>Partner Name</label>
            <b-input disabled :value="partner.partner_name"></b-input>
            <label class="mt-2">Partner ID</label>
            <b-input disabled :value="partner.partner_id"></b-input>
            <label class="mt-2">Registrations Require Validation</label>
            <b-input disabled :value="partner.requires_validation ? 'TRUE' : 'FALSE'"></b-input>
            <label class="mt-2">Registrations Paid by Partner</label>
            <b-input disabled :value="partner.paysOrders ? 'TRUE' : 'FALSE'"></b-input>
            <label class="mt-2">Partner Card on File</label>
            <b-input-group>
              <b-input 
                disabled 
                :value="checkCardOnFile(partner) ? partner.vgs_card_type.toUpperCase() : 'FALSE'"
              >
              </b-input>
              <b-input-group-append>
                <b-button variant="outline-primary" @click="openCcForm(partner)">Add new Card</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalCcForm from "../components/Molecules/ModalCcForm.vue";
import Header from "../components/Molecules/Header.vue";

export default {
  name: "PartnerProfile",
  data() {
    return {
      selectedPartner: null,
    };
  },
  methods: {
    async updatePartner() {

      const updateObj = await this.updateObj();

      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "partners/"+this.selectedPartner._id+"/",
          body: updateObj,
        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          this.$store.dispatch("refreshUser")
          this.$bvModal.hide('cc-form-modal')

        })
        .catch((err) => {
          this.stage = ''
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    openCcForm(partner) {
      this.$bvModal.show('cc-form-modal')
      this.selectedPartner = JSON.parse(JSON.stringify(partner)) 
      this.selectedPartner.vgs_card_expirationDate_alias =  'hello'

    },
    checkCardOnFile(partner) {
      return (
        !this.isEmpty(partner.vgs_card_type) && 
        !this.isEmpty(partner.vgs_card_number_alias) &&
        !this.isEmpty(partner.vgs_card_expirationDate_alias)
      );
    },
    isEmpty(str) {
      return (!str || str.length === 0 );
    },
    updateObj() {
      const obj = {};
      
      const orgSelectedPartner = this.$store.state.user.adminPartners.find(x => x._id === this.selectedPartner._id)
      
      const keys = Object.keys(this.selectedPartner);

      for(const key of keys) {
        //console.log(key, this.selectedPartner[key], 'vs', orgSelectedPartner[key])
        if(typeof orgSelectedPartner[key] == 'object') continue; 
        if (
            typeof orgSelectedPartner[key] == 'undefined' ||
            this.selectedPartner[key] !== orgSelectedPartner[key]
           ) {
          obj[key] = this.selectedPartner[key];
        }
      }
      //console.log('final updateObj',obj)
      return obj
    },
  },
  computed: {
  },
  async created() {
  
    if (!window.localStorage.token) return this.redirectLogin();

    await this.$store.dispatch("refreshUser")
    
  },
  components: {
    Header,
    ModalCcForm,

  },
}
</script>
<style lang="scss" scoped>
  .partnerProfile {
    display: flex;
    flex-direction: column;
    min-height: 40rem;
    height: 100%;
    background: #f5f5f5;
  }
  .main {
    text-align: left;
    margin: 2%;
  }
</style>